import api from "./api";

const LOGIN_EXPIRATION_DAYS = 90;
const COOKIE_NAME = "MaskSelectorCredentials";

function createCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setDate(date.getDate() + days);
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

export function logout() {
    eraseCookie(COOKIE_NAME);
}

export function isLoggedIn() {
    try
    {
        let credentials = getCurrentCredentials();
        return credentials.licenseCode !== "" && credentials.pin !== "";

    }
    catch
    {
        return false;
    }
}

export function getCurrentCredentials() {
    return JSON.parse(readCookie(COOKIE_NAME));
}

export async function validateCredentials(credentials) {
    try {
        const options = {
            headers: {'mss2dauth': 'mss2d-0eb091aba3b64aa9a4b911f6ae0d607d'}
        }
        let response = await api.post('license-codes-2d/verify', credentials, options);
        if (response.status === 200 && response.data.licenseCode === credentials.licenseCode)
        {
            createCookie(COOKIE_NAME, JSON.stringify(credentials), LOGIN_EXPIRATION_DAYS);
            return true;
        }
        else
        {
            return false;
        }
    } catch (error) {
        console.log("Error contacting license key service.");
        return false;
    }
}